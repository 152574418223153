<template>
  <div class="mb-60 baojia-box liucheng-top rounded-lg">
    <v-card class="pa-4 d-flex" flat>
      <div>
        <h4 class="mb-3">{{ $t("我的邀请码") }}</h4>
        <div class="mb-6">
          <div class="d-flex">
            <div class="mr-3">
              <v-img width="48" src="@/assets/images/person/pin.png"></v-img>
            </div>
            <div>
              <div class="font-600">{{ $t("分享注册码") }}</div>
              <div class="grey--text mt-2">
                {{
                  $t(
                    "复制注册码给好友，好友注册时填写该注册码，立即成为您的关联用户。"
                  )
                }}
              </div>
            </div>
          </div>
          <v-card
            class="ml-10 mt-4 d-md-flex align-center justify-space-between"
            flat
            rounded
            color="#FDD7CD"
            max-width="250"
          >
            <span class="pl-3 primary--text font-weight-bold text-h6">{{
              userinfo.code
            }}</span>
            <v-btn
              color="primary"
              @click="copyFn(userinfo.code)"
              height="42"
              depressed
              class="ml-3 text-body-1"
              >{{ $t("复制注册码") }}</v-btn
            >
          </v-card>
        </div>
        <div class="mb-6">
          <div class="d-flex">
            <div class="mr-3">
              <v-img
                width="48"
                src="@/assets/images/person/icon-QR@2x.png"
              ></v-img>
            </div>
            <div>
              <div class="font-600">{{ $t("分享二维码") }}</div>
              <div class="grey--text mt-2">
                {{
                  $t(
                    "复制您的专属二维码图片给好友，好友扫描该二维码注册，立即成为您的关联用户"
                  )
                }}
              </div>
            </div>
          </div>

          <div class="d-flex align-center ml-10 mt-4">
            <span>
              <img :src="QRImgUrl" />
            </span>
            <div>
              <div>
                <v-btn
                  color="#FDD7CD"
                  height="40"
                  @click="downCode"
                  depressed
                  style="color: red"
                  class="ml-3 text-body-1 mb-2"
                  >{{ $t("分享二维码") }}</v-btn
                >
              </div>
              <v-btn
                color="primary"
                height="40"
                depressed
                @click="downCode"
                class="ml-3 text-body-1"
                >{{ $t("下载二维码") }}</v-btn
              >
            </div>
          </div>
        </div>
        <div class="d-flex mb-6">
          <div class="mr-3">
            <v-img
              width="48"
              src="@/assets/images/person/icon-Serial-56@2x.png"
            ></v-img>
          </div>
          <div>
            <div class="font-600">{{ $t("分享邀请链接") }}</div>
            <div class="grey--text mt-2">
              {{
                $t(
                  "分享您的专属注册意请链接给好友，好友通过链接注册，立即成为关联用户"
                )
              }}
            </div>
          </div>
        </div>
        <v-card
          class="ml-10 mt-4 d-md-flex align-center justify-space-between"
          flat
          color="#FDD7CD"
          max-width="800"
        >
          <span class="pl-3 primary--text font-weight-bold text-h6">{{weburl+'?code='+userinfo.code}}</span>
          <v-btn
            color="primary"
            @click="copyFn()"
            height="42"
            depressed
            class="ml-3 text-body-1"
            >{{ $t("复制链接") }}</v-btn
          >
        </v-card>
      </div>
    </v-card>
    <!-- 复制弹窗 -->
    <v-dialog v-model="isfuzhiDialog" class="white" width="400" persistent>
      <v-card class="py-10 rounded-lg">
        <v-card flat color="#F2F2F2" class="py-4 ml-7 mr-7">
          <p class="text-h5 text-center mb-0">{{ $t("复制成功") }}</p>
        </v-card>
        <p class="mt-3 mb-5 text-center text-caption text--disabled">
          {{ $t("赶紧发给好友注册吧~") }}
        </p>
        <v-row class="ma-0">
          <v-spacer />
          <v-btn
            class="primary mx-auto pl-6 pr-6"
            depressed
            @click="isfuzhiDialog = false"
            >{{ $t("确定") }}</v-btn
          >
          <v-spacer />
        </v-row>
      </v-card>
    </v-dialog>
    <v-card class="pa-4 mt-6" flat>
      <div class="d-flex align-center justify-space-between mb-6">
        <div>
          <span class="text-body-1 font-weight-bold">{{
            $t("我的关联用户：")
          }}</span>
          <span class="text-body-1 font-weight-bold textred">{{info.num}}</span>
          <span class="text-body-3  textred cursor ml-4 lookinfo" @click="lookinfo">{{$t('查看明细')}}</span>
        </div>
        <div class="text-body-1 font-weight-bold">
          <span>{{ $t("累计收益：") }}</span>
          <span class="textred">¥{{info.allamount}}</span>
        </div>
      </div>
      <v-data-table
        :headers="headers"
        :items="datalist"
        :no-data-text="$t('暂无数据')"
        class="elevation-0"
        hide-default-footer
        disable-pagination
      >
        <template v-slot:item.amount="{ item }">
          <span class="textred"> ¥{{ item.amount }}</span>
        </template>
         <template v-slot:item.create_time="{ item }">
          <span> {{ item.create_time | formFilter}}</span>
        </template>
      </v-data-table>
      <div
        v-if="pagearr.pagetotal > 0"
        class="text-center d-flex mt-4"
        align="center"
      >
        <v-row align="center" class="justify-center">
          <v-pagination
            v-model="pagearr.page"
            :length="pagearr.pagetotal"
            total-visible="5"
            @input="getpagedata(1)"
          />
          <span class="mr-4 hidden-xs-only">{{ $t("条") }}</span>
          <span class="ml-6 hidden-xs-only">
            {{ $t("跳转至") }}
            <input
              v-model="pagearr.page"
              autocomplete="off"
              style="width: 80px"
              class="border in-border pl-2 pt-1 ml-2 mr-2 pb-1"
              type="number"
              @blur="getpagedata(1)"
            />
            {{ $t("页") }}
          </span>
        </v-row>
      </div>
    </v-card>
  </div>
</template >
<script>
import QRCode from "qrcode";
export default {
  data() {
    return {
      QRImgUrl: "kkkkk",
      QRlink: "www.xxx.com",
      userinfo: {},
      isfuzhiDialog: false,
      weburl:'http://ahui.vn/sign-up',
      pagearr: {
        pageList: [15, 30, 50, 100, 500, 1000, 2000],
        page: 1,
        pagenum: 15,
        pagetotal: 50,
        total: 0,
      },
      headers: [
        {
          text: this.$t("结算时间"),
          align: "start",
          sortable: true,
          class: "title-bg",
          value: "create_time",
        },
        { text: this.$t("用户"), value: "nick_name", class: "title-bg" },
        { text: this.$t("获得收益"), value: "amount", class: "title-bg" },
      ],
      datalist: [],
      info:{
        allamount:0,
        num:0
      }
    }
  },
  created() {
    this.userinfo = this.$store.state.USERINFO;
    this.getQRcode();
  },
  mounted() {
    this.getData()
  },
  methods: {
    getQRcode() {
      let opts = {
        errorCorrectionLevel: "L", //容错级别
        type: "image/png", //生成的二维码类型
        quality: 0.3, //二维码质量
        margin: 3, //二维码留白边距
        width: 90, //宽
        height: 90, //高
        text: "http://www.xxx.com", //二维码内容
        color: {
          dark: "#666666", //前景色
          light: "#fff", //背景色
        },
      };
      QRCode.toDataURL(this.userinfo.code, opts, (err, url) => {
        if (err) throw err;
        //将生成的二维码路径复制给data的QRImgUrl
        this.QRImgUrl = url;
      });
    },
    // 下载二维码
    downCode() {
      let base64String = this.QRImgUrl;
      const id = Number(
        Math.random().toString().substr(3, 5) + Date.now()
      ).toString(36);
      let blob = this.base64StringToBlob(base64String);
      let url = URL.createObjectURL(blob);
      let save_link = document.createElementNS(
        "http://www.w3.org/1999/xhtml",
        "a"
      );
      save_link.href = url;
      save_link.download = "邀请二维码" + id;
      save_link.click();
    },
    base64StringToBlob: function (base64String) {
      var arr = base64String.split(","),
        mime = arr[0].match(/:(.*?);/)[1],
        bstr = atob(arr[1]),
        n = bstr.length,
        u8arr = new Uint8Array(n);
      while (n--) {
        u8arr[n] = bstr.charCodeAt(n);
      }
      return new Blob([u8arr], { type: mime });
    },
    // 复制二维码
    copyFn(data) {
      const url = this.weburl+'?code='+this.userinfo.code;
      const oInput = document.createElement("input");
      oInput.value = url;
      document.body.appendChild(oInput);
      oInput.select(); // 选择对象;
      document.execCommand("Copy"); // 执行浏览器复制命令
      this.isfuzhiDialog = true;
      oInput.remove();
    },
    lookinfo(){
      this.$router.push({path:'/user/rebateDetails'})
    },
    getData(){
      this.$api.user.get_below_data({page:1,page_size:10}).then((res)=>{
        if(res.status=='success'){
          this.datalist=res.data
          this.info.allamount=res.amount
          this.info.num=res.child_num
          this.pagearr.pagetotal = Math.ceil(
            parseInt(res.total) / this.pagearr.pagenum
          );
        }else {
          this.info.allamount=0
          this.info.num=res.child_num
          this.datalist=[]
          this.pagearr.pagetotal = 0;
        }
      })
    },
  },
};
</script>
<style scoped>
  /deep/ .v-pagination  .v-pagination__item{
  box-shadow: none;
  background: #F2F2F2;
}
/deep/ .v-pagination__navigation{
  box-shadow: none;
  background: #F2F2F2 !important;
}
</style>
<style>
  .lookinfo{
    padding: 0px 7px;
    border: 1px solid;
    border-radius: 17px;
    display: inline-block;
  }
.textred {
  color: #f63605;
}
.title-bg {
  background: #f2f2f2 !important;
  color: #f2f2f2;
}
</style>